.root {
    width: 100%;
    min-height: 100%;
    margin-top: 20px;
    display:inline-block;
}

.container{
    justify-content: center;
    padding: 20px;
}

.img {
   
    min-width: 100px;
    max-width: 100px;

}