.root {
    text-align: center;
}

.containerRoot {
    background-color: #3AFEE2;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 390px;
    max-height: 390px;
}

.title {
    font-size: 30px;
    max-width: 300px;
    color: #364850;
    font-weight: bold;
    margin: 0;
    margin-top: 10px;
}

.titleMethodology {
    font-size: 30px;
    color: #364850;
    font-style: italic;
    position: relative;
    font-weight: 600;
    top: -30px;
}


.text {
    text-align: left;
    margin-left: 20px;
    font-size: 16px;
    margin-top: -30px;
    color: #364850;
    font-weight: 500;
    line-height: 20px;
    max-width: 90%;
}

.text strong {
    font-weight: 600;
    font-size: 15px;
}

.img {
    position: absolute;
    bottom: 45px;
    left: 45px;
}

.item {
    margin-top: 20px;
}

.imgSpecial {
    margin-left: -25px;
}

.itemContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    padding-bottom: 30px;
}

.description {
    text-align: center;
    color: #364850;
    font-weight: 500;
    max-width: 120px;
    min-width: 120px;
}

.descriptionSpecial {
    max-width: 240px;
    min-width: 240px;
    color:#364850;
    font-weight: 500;
}

.imgDesktop {
    display: none;
}

@media (min-width : 800px) {
    .root {
        margin-top: 110px;
    }
    
    .containerRoot {
        min-height: 290px;
        max-height: 290px;
        align-items: flex-start;
        padding-top: 40px;   
    }
    
    .itemContainer  {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 0;
    }

    .imgDesktop {
        display: block;
        position: absolute;
        right: -80px;
        top: 65px;
        max-height: 200px;
    } 

    .title, .titleMethodology {
        align-self: flex-start;
        margin-left: 1.5rem;
        font-size: 40px;
        min-width: 90%;
        text-align: left;
        margin-top: 25px;
    }
    
    .text {
        align-self: flex-start;
        margin-left: 1.5rem;
        margin-top: -80px;
        font-size: 20px;
        max-width: 66%;
        line-height: 30px;
    }
    
    .text strong {
        font-size: 18px;
    }

    .img {
       display: none;
    }
    
    .item {
        display: flex;
        flex-direction: row;
        margin: 40px 40px 0 40px;
    }
    
    .imgItem {
        max-height: 55px;
        position: relative;
        top: 10px;
    }
    
    .imgSpecial {
        margin-left: 0;
        max-height: 70px;
        max-width: 60px;
    }
    
    .description {
        text-align: left;
        margin-left: 15px;
        color: #364850;
        font-size: 20px;
       
    }
    
    .descriptionSpecial {
        text-align: left;
        max-width: 300px;
        margin-left: 20px;
        color:#364850;
        font-size: 20px;
        padding-right: 15px;
    }
}

@media (min-width : 1500px) {
    .text, .title, .titleMethodology {
        margin-left: 2.3rem;
    }
}