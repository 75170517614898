.root {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 15px;
    margin-bottom: 20px;
}
    
.title {
    text-align: center;
    flex-wrap: nowrap;
    color:#364850;
    font-size: 40px;
    text-transform: lowercase;
    letter-spacing: .3px;
    font-family: 'Montserrat', 'ExtraBold';
}

.title::first-letter {   
    text-transform: uppercase;
}
    
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 90%;
    margin: auto;      
    position: relative;
    top: -10px;
}

@media(min-width: 450px) {
    .container {
        max-width: 390px;
    }
}

.containerDesktop {
    display: none;
}

.imgSize,.imgExo {
    min-width: 100px;
    max-width: 100px;
    max-height: 65px;
    margin-top: 15px;
    object-fit: contain;
}

@media (min-width : 1000px) {
    .title {
        text-align: left;
        max-width: 700px;
        margin-left: 0;
        font-size: 42px;
        text-transform: none;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .container {
        display: none;
    }

    .containerDesktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .imgSize, .imgExo {
        min-width: 170px;
        max-width: 170px;
        max-height: 50px!important;
        min-height: 50px!important;
        margin: 10px;
    }   

    .imgSmall {
 
        max-height: 80px;
    }

    .imgExo {
        min-height: 63px!important;
    }

    .containerDesktop1, .containerDesktop2 {
        display: flex;
        align-items: center;
    }

    .containerDesktop1 {
        min-width: 70%;
        justify-content: space-between;
        margin-top: 20px;
    }

    .containerDesktop2 {
        min-width: 58%;
        justify-content: space-between;
        margin-top: 20px;
    }

    .titleRoot {
        border-bottom: .2px solid rgba(54, 72, 80, .2);
        width: 95%;
        margin: auto;
    }
}
