.root {
    width: 95%;
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    height: 300px;
}

.number {
    color: rgba(52, 72, 80,.4);
    font-size: 50px;
    text-align: center;
    line-height: 30px;
    margin: 10px 0 10px 0;
    font-weight: bolder;
}

.text {
    text-align: center;
    text-transform: uppercase;
    color: #364850;
    letter-spacing: .5px;
    font-size: 15px;
    font-weight: bold;
}

.imgRoot {
    min-width: 33%;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(min-width: 800px) {
    .root {
        margin-top: 20px;
        max-height: 200px;
        min-height: 200px;
    }

    .img {
        max-width: 40%;
        min-width: 40%;
        min-height: 70%;
        max-height: 70%;
        object-fit: contain;
    }

    .imgRoot {
        min-width: 30%;
        max-width: 30%;
    }

    .text {
        min-width: 130px;
        max-width: 130px;
        font-size: 16px;
        margin-left: 8px;
    }

    .number {
        font-size: 50px;
        font-weight: bolder;
    }

    .numberAndText {
        display: flex;
        align-items: center;
        position: relative;
        top: -15px;
    }
}

@media(min-width: 1500px) {
    .text {
        font-size: 20px;
    }

    .number {
        font-size: 60px;
    }

    .root {
        max-height: 270px;
        min-height: 270px;
    }
}