.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F1F3F6B3;
}

.title {
    display: flex;
    justify-content: center;
    color: #364850;
    font-size: 30px;
}

.socialNetwork {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    max-height: 60px;
    color: #253251;
    font-family: 'Montserrat-Medium' !important;
    width: 85%;
    margin: auto;
    margin-top: 18px;
}

.icon {
    min-width: 24px;
}

.text {
    margin-left: 10px;
    color: #253251;
}

.submitButton, .submitButtonDisable, .hideButton {
    font-family: 'Montserrat-medium' !important;
    margin-top: 10px;
    min-width: 100%;
    max-height: 40px;
    background-color: #2349E2;
    color: white;
    letter-spacing: .5px;
    border-radius: 8px;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    font-size: 18px;
    margin-top: 30px;
    z-index: 5;
}

@keyframes vibrate {
    0% { transform: translateX(0); }
    25% { transform: translateX(-3px) rotate(3deg); }
    50% { transform: translateX(3px) rotate(-3deg); }
    75% { transform: translateX(-3px) rotate(3deg); }
    100% { transform: translateX(0); }
}

.submitButtonDisable{
    animation: vibrate 0.5s ease ;
}

.hideButton {
    animation: blur-out-contract 1.8s linear both;
}

@keyframes blur-out-contract {
    0% {
        transform: scale(1);
        filter: blur(0.01px);
    }
    100% {
        transform: scale(0);
        filter: blur(12px);
        opacity: 0;
    }
}

.iconButton {
    margin-left: 20px;
}

.form { 
    width: 85%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: auto;
}

.input { 
    padding-left: 10px;
    margin-top: 10px;
    height: 50px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 32px #0000001A;
}

.inputArea {
    padding: 10px;
    margin-top: 10px;
    padding-bottom: 140px;
    border-radius: 8px;
    border: none;
    resize: none;
    box-shadow: 0px 0px 32px #0000001A;
    text-overflow: hidden;
    white-space: nowrap;
}

.inputArea::placeholder {
    color: #374653;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: initial;
    height: 100%;
    width: 100%;
}

::placeholder {
    color: #374653;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-overflow: hidden;
    white-space: nowrap;
}

.socialNetworks {
    max-width: 100%;
}

.input:focus, .inputArea:focus {
    outline: none;
}

.emailSuccesText, .emailSuccesTextShow {
    color: #364850;
    font-family: 'Montserrat-Light', sans-serif;
    position: relative;
    padding: 10%;
    margin-top: -20%;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    transition: opacity 3.1s ease-in-out;
    z-index: 1;
    opacity: 0;
}

.emailSuccesTextShow {
    opacity: 1;
}

.recaptchaBox {
    height: max-content;
    margin: auto;
    width: 100%;
    padding: 15px 0;
    align-items: center;
}

.recaptcha {
    max-width: max-content;
    position: relative;
    left: 0;
}

.error {
    max-width: 100%;
    color: #CF010B;
    font-family: 'Montserrat-Light', sans-serif;
}

@media(min-width: 1028px) {
    .root {
        max-height: 700px;
        min-height: 700px;
        position: absolute;
        width: 50%;
        opacity: 1;
    } 

    .rootDesktop {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: max-content;
        padding: 0;
    }
    
    .title {
        justify-content: left;
        padding-left: 5%;
        text-transform: uppercase;
        font-size: 38px;
    }

    .socialNetworks {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        max-height: 100px;
        flex-wrap: wrap;
        width: 80%;
        overflow: hidden;
        padding-left: 5%;
        margin-top: -20px;
     }

    .socialNetwork {
        width: max-content;
        font-size: 12px;
        margin: 0;
        flex-wrap: wrap;
        padding-right: 15px;
    }

    .icon {
        width: 22px;
    }

    .form {
        width: 70%;
        max-height: 400px;
        padding: 10px;
        margin-left: 2rem;
        position: relative;
        top: 10px;
    }

    .input { 
        height: 50px;
        box-shadow: 0px 0px 32px #0000001A;
    }
    
    .inputArea {
        padding: 10px;
        padding-bottom: 90px;
    }

    .inputArea::placeholder{
        font-size: 12px;
    }
    
    ::placeholder {
        font-size: 12px;
    }

    .submitButton, .submitButtonDisable, .hideButton {
        margin-top: 5px;
        max-height: 40px;
        font-size: 14px;
        margin-top: 15px;
    }

    .emailSuccesText, .emailSuccesTextShow {
        font-size: 18px;
        padding-left: 5%;
    } 

    .recaptcha {
        left: 0;
        margin: auto;
    }
}

