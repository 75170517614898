.root {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    opacity: 1;
    position: fixed;
    z-index: 20;
    top: 0;
}

.languageContainer {
    display: none;
}

.menuLogo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.itemsDesktop {
    display: flex;
    position: relative;
    left: -6rem;
}

.link {
    text-decoration: none;
}

.item {
    color: #2349E2;
    font-size: 12px;
    outline: none;
    font-weight: 500;
    text-transform: uppercase;
    margin: 3px;
}

.itemsDesktopRoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemAskForBudget:hover {
    color: #2349E2;
    background-color: #FFFFFF;
    border: 3px solid #2349E2;
}

.itemRoot span {
    color: #2349E2;
}

.itemAskForBudget {
    background-color: #2349E2;
    color: #FFFFFF;
    border-radius: 10px;
    max-width: 150px;
    font-size: 13px;
    padding: 5px 7px;
    letter-spacing: .1px;
}

.item:hover{
    color: #3AFEE2;
}

.itemRoot {
    display: flex;
    align-items: center;
}

.logo {
    max-width: 110px;
    max-height: 25px;
}

.menu, .menuEcommerce {
    max-width: 120px;
    max-height: 30px;
    cursor: pointer;
}

@media(min-width: 800px) {
    .root {
        justify-content: space-between;
        padding: 10px 40px 10px 20px;
    }

    .languageContainer {
        display: flex;
        position: relative;
        left: -5rem;
    }

    .menuLogo {
        width: 20rem; 
        flex-direction: row-reverse;
    }

    .logo {
        width: 180px;
        height: 34px;
        max-width: unset;
        max-height: unset;
        margin-top: 1px;
    }

    .menu {
        width: 3rem;
        height: 3rem;
    }

    .menuEcommerce {
        display: none;
    }
}

@media(max-width: 800px) {
    .itemsDesktop {
        display: none;
    }
}

@media(min-width: 900px) {
    .item {
        font-size: 14px;
    }

    .itemAskForBudget {
        margin-left: 2px;
        font-size: 14px;
    }
}

@media(min-width: 1020px) {
    .item {
        padding: 0 10px;
    }

    .itemAskForBudget {
        font-size: 15px;
        padding: 10px 15px;
        margin-left: 0;
    }
}

@media(min-width: 1500px) {
    .itemAskForBudget {
        padding: 15px 25px;
        font-size: 16px;
    }

    .item {
        font-size: 16px;
    }
}