.root {
    background-color: #364850;
    display: flex;
    justify-content: space-around;
    height: 40px;
    align-items: center;
    min-width: 100%; 
}

.links {
    display: none;
}

.socialMedia {
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ig {
    position: relative;
    top: 2px;
}

.notClasses {
    position: relative;
}

@media(min-width: 1500px) {
    .root {
        background-color: #2349E2;
        height: 85px;
    }

    .spam {
        color: white;
    }

    .spamNull {
        display: none;
    }

    .socialMedia { 
        max-width: 150px;
        justify-self: flex-end;
    }

    .links {
        display: flex;
        min-width: 70%;
        justify-content: center;
    }

    .item {
        display: flex;
        align-items: center;
    }

    .name {
        color: #FFFFFF;
        text-transform: uppercase;
        margin: 8px;
        letter-spacing: .8px;
        font-weight: 500;
    }

    .link {
        text-decoration: none;
        z-index: 5000;
    }

    .name:hover {
        color: #F1F3F6B3;    
    }

    .logo {
        width: 160px;
        margin-top: 5px;
        position: relative;
        left: 7rem;
    }
} 




