.root {
    position: relative;
    height: 550px;
    max-width: 270px;
    margin: auto!important;
    overflow: hidden;
}

.title {
    font-size: 36px;
    font-weight: 600;
    color: #364850;
    font-size: 18px;
    width: 200px;
    margin-left: 10px;
    margin-top: 40px;
    letter-spacing: .5px ;
}

.points {
    position: absolute;
    bottom: -10px;
    right: -20px;
    width: 80px;
    height: 130px;
}

.itemRoot {
    position: relative;
}

.containerTitleAndImg {
    margin-top: 20px;
}

.imagesRoot  {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.imgIcon {
    height: 25px;
    width: 70px;
}

.spsImg {
    width: 40px;
    height: 40px;
}

.mercadoLibreImg {
    width: 100px;
    height: 30px;
}

.containerTitle {
    display: flex;
    align-items: center;
}

.titleImg {
    font-size: 16px;
    color: #364850;
    font-weight: 500;
    line-height: 5px;
    margin-left: 5px;
}

.iconCheck {
    width: 40px;
    height: 40px;
}

@media (min-width: 800px) {
    .root {
        min-width: 95%;
        max-width: 95%;
        height: 300px;
    }

    .title {
        font-size: 20px;
        width: 700px;
        margin-left: 5%;
    }

    .containerTitleAndImg {
       width: 300px;
       margin: 20px 10px
    }

    .itemRoot {
        display: flex;
        justify-content: center;
    }

    .points {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100px;
        height: 180px;
    }
}

@media (min-width: 900px) {
    .title {
        font-size: 25px;
        width: 800px;
    }
}

@media (min-width: 1100px) {
    .title {
        font-size: 30px;
        width: 1000px;
    }
}