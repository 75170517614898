.root {
    display: flex;
    flex-direction: column;
    max-width: 2.5rem;
    position: absolute;
    top: 240px;
    left: 10%;
    z-index: 10;
}

.numbers {
    display: flex;
    flex-direction: column; 
    position: relative;
}

.button {
    font-weight: bold;
    text-decoration: 1px solid underline #364850;
    color: #364850;
    padding-top: 15px;  
}

.increment, .decrement {
    display: none;
}

@media(min-width: 315px) {
    .root {
        left: 12%;
    }
}

@media(min-width: 415px) {
    .root {
        left: 15%;
    }
}

@media(min-width: 470px) {
    .root {
        left: 17%;
    }
}

@media(min-width: 590px) {
    .root {
        left: 19%;
    }
}

@media(min-width: 800px) {
    .root {
        flex-direction: row;
        top: 150px;
        left: -6rem;
    }

    .button {
        font-size: 20px;
        font-weight: bold;
        padding-top: 20px;  
        position: relative;
        top: 15px;
    }

    .increment {
        display: flex;
        transform: matrix(0, 1, -1, 0, 0, 0);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        letter-spacing: 0px;
        color: #E8EAF2;
        opacity: 1;
        font-size: 60px;
        height: 50px;
        position: relative;
        top: 90px;
        left: -2.5rem;
    }
    
    .decrement {
        display: flex;
        transform: matrix(0, -1, 1, 0, 0, 0);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-f3f3f7);
        text-align: left;
        letter-spacing: 0px;
        font-size: 60px;
        color: #F3F3F7;
        opacity: 1;
        height: 50px;
        position: relative;
        top: 90px;
        left: 3rem;
    }
}

@media(min-width: 1800px) {
    .decrement, .increment {
       font-size: 68px;
    }

   .root {
       top: 260px;
   }
}