.root {
    width: 100%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #364850;
}

.title {
    font-size: 32px;
    max-width: 360px;
    color: #364850;
    margin: 20px auto;
    text-align: center;
    line-height: 27px;
    padding-top: 20px;
}

.slide {
    max-width: 110%;
    max-height: 350px;
    display: flex;
    flex-direction:row;
    position: relative;
    left: .4rem;
    max-width: 450px;
}

.container {
    display: flex;
    flex-direction: column;
    max-height: 360px;
    min-width: 230px;
    border-radius: 16px;
    margin: 40px;
    margin-left: 75px;
    margin-top: -70px;
    padding: 15px;
    font-size: 13pt;
    font-weight: 500;
    color: #364850;
    font-family:'Montserrat';
    background-position: 80%;
    background-repeat: no-repeat;
    background-size: 80%;
}

.owner {
    display: flex;
    flex-direction: column;
}

.client {
    margin-top: -20px;
    font-weight: bolder;
}

.img {
    height: 60px;
    width: 70px;
    align-content: flex-start;
    margin-top: 15px;  
}

.imgClose {
    width: 25px;
    height: 25px;
    margin-left: 210px;
    margin-top: -35px;
}

.li {
    list-style: none;
}

@media(min-width: 550px) {
    .slide {
        margin-left: 3rem;
    }
}

@media(min-width: 620px) {
    .slide {
        margin-left: 4.5rem;
    }
}

@media(min-width: 640px) {
    .slide {
        margin-left: 6rem;
    }
}

@media(min-width: 660px) {
    .slide {
        margin-left: 7rem;
    }
}

@media(min-width: 700px) {
    .slide {
        margin-left: 9rem;
    }
}

@media(min-width: 750px) {
    .slide {
        margin-left: 11rem;
    }
}

@media(min-width: 800px) {
    .slide {
        margin-left: 12rem;
    }
}

@media(min-width: 860px) {
    .slide {
        margin-left: 14rem;
    }
}

@media(min-width: 920px) {
    .slide {
        margin-left: 15rem;
    }
}

@media(min-width: 960px) {
    .slide {
        margin-left: 17rem;
    }
}

@media(min-width: 1050px) {
    .slide {
        margin-left: 19rem;
    }
}

@media(min-width: 1090px) {
    .slide {
        margin-left: 20rem;
    }
}

@media(min-width: 1100px) {
    .root {
        display: none;
    }
}
