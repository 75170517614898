.b2b h3 {
    width: 90%;
    margin: auto;
    font-size: 34px;
    color: #364850;
    font-weight: 300;
    font-style: italic;
    padding-top: 20px;
}

.b2b p {
    width: 90%;
    margin: auto;
    font-size: 16px;
    color: #364850;
    margin-top: 10px;
    font-weight: 400;
}

.b2b {
    width: 99%;
    margin: auto;
    margin-top: 10%;
    background-color: #F1F3F6;
    max-height: 550px;
    margin-bottom: 170px;
}

.imgNewsanB2b {
    width: 100%;
    z-index: -1;
}

.imgMagentoPhonos {
    width: 120%;
    margin-left: -40px;
    z-index: 1;
    position:relative;
    top: -80px;
    overflow: hidden;
}

.contactFormRoot { 
    width: 100%;
}

@media(min-width:390px){
    .b2b {
        margin-bottom: 92%;
    }
}

@media(min-width:800px) {
    .b2b {
        max-height: 700px;
        min-height: 700px;
        position: relative;
        z-index: -1;
        margin-bottom: 5%;
        margin-top: 50%;
    }

    .b2b h3 {
        padding-top: 50px;
        width: 90%;
        font-size: 32px;
    }

    .b2b p {
        width: 30%;
        margin-left: 90px;
        font-size: 18px;
    }

    .imgNewsanB2b {
        width: 65%;
        right: 0;
        position: absolute;
        z-index: 1;
        top: -20%;
    }

    .imgMagentoPhonos {
        width: 70%;
        position: absolute;
        z-index: 2;
        top: 200px;
    }

    .contactFormRoot { 
        width: 50%;
    }

    .contactFormAndUbication {
        flex-direction: row-reverse;
        display: flex;
        max-height: 695px!important;
        min-height: 695px!important;
        position: relative;
        top: -5px;
    }
}

@media(min-width:1200px) {
    .b2b {
        margin-bottom: 20%;
        margin-top: 50%;
    }
}