.root {
    max-width: 100%;
    height: 400px;
    background-color: blue !important;
    opacity: .9;
    z-index: 5;
}

.rootBackground {
    width: 100%;
    height: 100%;
    opacity: .8;
    z-index: 6;
}

@media (min-width : 1028px){
    .root {
        width: 50%;
        min-height: 700px;
        max-height: 700px;
    }
}