.root {
    padding-left: 9%;
    padding-top: 10px;
}

.subTitle {
    text-transform: uppercase;
    letter-spacing: .1px;
    font-weight: 500;
    font-size: 15px;
    color: #364850;
    margin: 0;
    padding: 0;
}

.title {
    max-width: 240px;
    min-width: 240px;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    color: #364850;
    margin: 10px 0 0 0;
    letter-spacing: .7px;
}

.title span {
    color: #2349E2;
    margin-left: 8px;
}

@media(min-width: 315px) {
    .root {
        padding-left: 12%;
    }

    .title {
        font-size: 32px;
        max-width: 280px;
        min-width: 280px;
    }
}

@media(min-width: 415px){
    .root {
        padding-left: 15%;
    }
}

@media(min-width: 470px){
    .root {
        padding-left: 17%;
    }
}

@media(min-width: 590px){
    .root {
        padding-left: 19%;
    }
}

@media(min-width: 740px) {
    .title {
        max-width: 280px;
        min-width: 280px;
    }
}

@media(min-width: 800px) {
    .root {
        padding-left: 25%;
    }
}

@media(min-width: 855px) {
    .root {
        padding-left: 23%;
    }
}

@media(min-width: 905px) {
    .root {
        padding-left: 20%;
    }

    .title {
       font-size: 45px;
       max-width: 380px;
       min-width: 380px;
       line-height: 40px;
    }
}

@media(min-width: 1050px) {
    .root {
        padding-left: 19%;
    }
}

@media(min-width: 1150px) {
    .root {
        padding-left: 17%;
    }
}

@media(min-width: 1250px) {
    .root {
        padding-left: 15%;
    }
}

@media(min-width: 1450px) {
    .root {
        padding-left: 13%;
    }
}

@media(min-width: 1730px) {
    .root {
        padding-left: 11%;
    }

    .title {
        line-height: 42px;
    }
}

@media(min-width: 1800px) {
    .root {
        padding-left: 13.5%;
        position: relative;
        top: 100px;
    }

    .title {
        font-size: 58px!important;
        line-height: 55px!important;
        max-width: 490px!important;
        min-width: 490px!important;
        margin: 5px 0 0 0!important;
    }
}