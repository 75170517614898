.root {
    background-color: #F1F3F6B3;
    padding-bottom: 40px;
}

.title {
    color: #2349E2;
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: .5px;
    padding-top: 45px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.itemTitle {
    background-color: #2349E2;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
}

.subtitle, .subtitleSmall {
    margin: 0;
    padding: 0;
    color: #2349E2;
    text-transform: uppercase;
    text-align: center;
    max-width: 60%;
    font-weight: 500;
}

.text {
    text-align: center;
    max-width: 75%;
    color: #364850;
}

.itemsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media(min-width: 1070px) {
    .itemsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 340px;
    }

    .item {
        align-items: flex-start;
        max-width: 20%;
        min-width: 20%;
        min-height: 200px;
        max-height: 200px;
        position: relative;
        top: -80px;
    }

    .text,.subtitle, .subtitleSmall {
        text-align: left;
    }

    .text {
        margin-top: 5px;
        font-size: 19px;
    }

    .subtitle, .subtitleSmall {
        max-width: 80%;
        min-height: 80px;
        max-height: 80px;
        font-size: 20px;
    }

    .subtitleSmall {
        max-width: 50%;
    }

    .title {
        text-align: left;
        margin-left: 3rem;
        margin-top: 0;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: 1.5px;
    }
    
    .img {
        position: absolute;
        top: 28px;
        right: 10px;
        transform: rotate(-90deg);
    }

    .root {
        padding: 0;
        min-height: 380px;
        background-color: #FFFFFF;
    }
}

@media(min-width: 1435px) {
    .subtitle, .subtitleSmall {
        min-height: 70px;
        max-height: 70px;
    }
}

@media(min-width: 1650px) {
    .subtitle, .subtitleSmall {
        min-height: 50px;
        max-height: 50px;
    }

    .title {
        margin-left: 10rem;
    }
    
    .itemsContainer {
        min-height: 300px;
    }

    .item {
        top: -30px;
    }
}