.root {
    display: flex;
    flex-direction: column;
    width: 92%;
    color: #364850;
    padding: 20px;
    position: relative; 
}

.title {
    font-size: 30px;
    position: relative;
    top: 20px;
    font-weight: bold!important;
}

.img {
    display: block;
    position: absolute; 
    top: -8px;
}

.text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}

.mouseRoot {
    display: none;
}

@media(min-width: 800px) { 
    .root {
        flex-direction: row;
        align-items: top;
        top: 5px;
        margin: auto;
        width: 93%;
    }

    .img {
        display: none;
    }

    .title {
        font-size: 40px;
        max-width: 180px;
        position: relative;
        margin: 0;
        text-align: end;
        height: 175px;
        line-height: 40px;
        top: 0;
        height: 140px;
    }

    .text {
        font-size: 17px;
        letter-spacing: 0;
        height: 175px;
        line-height: 20px;
        min-width: 75%;
        max-width: 75%;
        color: #364850;
        margin: 0;
        padding-left: 20px;
        height: 140px;
    }

    .mouseRoot {
        display: flex;
        flex-direction: column;
        max-width: 20px;
        align-items: center;
        cursor: pointer;
        margin: auto;
    }

    .mouseButton {
        z-index: 10;
        -webkit-animation: move 1s infinite  alternate;
        animation: move .5s infinite  alternate;
    }

    @-webkit-keyframes move {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }

    @keyframes move {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }
}

@media(min-width: 1200px) { 
    .root {
        position: relative;
        left: -1.2rem;
    }
    .title {
        font-size: 46px;
        line-height: 42px;
        max-width: 230px;
        height: 150px;
    }

    .text {
        font-size: 21px;
        line-height: 32px;
        min-width: 70%;
        max-width: 70%;
        height: 150px;
    }
}