.root {
    position: relative;
    margin-top: 100px;
}

.technology {
    width: 90%;
    margin: auto;
    margin-top: 55px;
}

.technology h3 {
    margin: 20px 0 0 0;
    color: #364850;
    font-size: 28px;
}

.technology p {
    color: #364850;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.technology_text {
    color: #364850;
    font-size: 16px;
    word-spacing: 0;
}

.technology_textStoreNewsan {
    color: #364850;
    font-size: 16px;
    word-spacing: 0;
}

.technology_subtitle {
    color: #364850;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 30px !important;
    margin-bottom: 10px;
}

.technology_subtitle h4 {
    padding-right: 0.5%;
    color: #364850;
    font-size: 18px;
    word-spacing: 0;
    font-weight: 400;
}

.technology_subtitle p {
    padding-right: 0.5%;
    color: #364850;
    font-size: 16px !important;
    word-spacing: 0;
    padding-top: 20px;
}

.technology_subtitlePrisma {
    color: #364850;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 30px !important;
    margin-bottom: 10px;
}

.technology_subtitlePrisma h4 {
    padding-right: 0.5%;
    color: #364850;
    font-size: 18px;
    word-spacing: 0;
    font-weight: 400;
}

.technology_subtitlePrisma p {
    padding-right: 0.5%;
    color: #364850;
    font-size: 14px !important;
    word-spacing: 0;
    padding-top: 20px;
}

.technology_subtitleStoreNewsan {
    color: #364850;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 20px !important;
    margin-bottom: 10px;
}

.technology_subtitleStoreNewsan h4 {
    padding-right: 0.5%;
    color: #364850;
    font-size: 16px;
    word-spacing: 0;
    font-weight: 400;
}

.technology_subtitleStoreNewsan p {
    padding-right: 0.5%;
    color: #364850;
    font-size: 12px !important;
    word-spacing: 0;
    padding-top: 18px;
}

.store h3 {
    width: 85%;
    margin: auto;
    font-size: 35px;
    color: #364850;
    font-weight: 300;
    font-style: italic;
}

.store p {
    width: 85%;
    margin: auto;
    font-size: 16px;
    color: #364850;
    margin-top: 10px;
    font-weight: 400;
}

.store {
    margin: 35px 0 0 0;
}

.storeVodafone {
    margin-top: 20%;
}

.imgStore {
    width: 100%;
    margin-top: 28px
}

.storeDesktop,
.storeDesktopExo,
.storeDesktopFelfort {
    display: none;
}

.methodology p {
    width: 85%;
    font-size: 22px;
    color: #364850;
    font-weight: bold;
    align-items: center;
    text-align: center;
    margin: 40px auto;
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.generalButtons {
    display: flex;
    position: relative;
    width: 45%;
    margin-left: 2px;
    margin-top: 20px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
}

.buttonsContainerNewsanMagento {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
}

.buttons h3 {
    width: 98%;
    margin: auto;
    font-size: 28px;
    text-align: center;
    color: #364850;
    font-weight: lighter;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttonsOtherProjectsNewsanMagento h3 {
    width: 98%;
    margin: auto;
    margin-top: 30px;
    font-size: 28px;
    text-align: center;
    color: #364850;
    font-weight: lighter;
    font-style: italic;
}

.buttonsOtherProjects h3 {
    width: 90%;
    margin: auto;
    font-size: 28px;
    text-align: left;
    color: #364850;
    font-weight: lighter;
    font-style: italic;
    margin-top: 45px;
}

.otherProjectsTitleVodafone {
    width: 90%;
    margin: auto;
    font-size: 28px;
    text-align: left;
    color: #364850;
    font-weight: 300 !important;
    font-style: italic;
    line-height: 1;
}

.buttonRoot {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    position: absolute;
    top: -20px;
    left: 10px;
}

.buttonRoot a {
    text-decoration: none;
    width: 100%;
}

.buttonRootNewsanMagento {
    display: flex;
    flex-direction: row;
    width: 40%;
    margin: auto;
    margin-top: 15px;
}

.buttonRootNewsanMagento a {
    text-decoration: none;
    width: 100%;
}

.button {
    border-radius: 10px;
    background-color: #3afee2;
    color: #364850;
    justify-content: center;
    display: flex;
    width: 176px;
    height: 55px;
    align-items: center;
    font-size: 16px;
    margin: 10px 0 10px 7px;
}

.buttonNewsanMagento {
    border-radius: 10px;
    background-color: #3afee2;
    color: #364850;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 55px;
    align-items: center;
    font-size: 16px;
}

.bRoot {
    display: flex;
    flex-direction: row;
}

.bRoot a {
    text-decoration: none;
    width: 100%;
}

.bRootNewsanMagento {
    display: flex;
    flex-direction: row;
    width: 43%;
    margin: auto;
    margin-top: 10px;
}

.bRootNewsanMagento a {
    text-decoration: none;
    width: 100%;
}

.container {
    border-radius: 10px;
    background-color: #3afee2;
    color: #364850;
    justify-content: center;
    display: flex;
    width: 176px;
    height: 55px;
    align-items: center;
    font-size: 16px;
    margin: 10px 0 10px 0;
}

.containerNewsanMagento {
    border-radius: 10px;
    background-color: #3afee2;
    color: #364850;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 55px;
    align-items: center;
    font-size: 16px;
    margin: 10px 0 10px 0;
}

.containerVodafone {
    border-radius: 10px;
    background-color: #3afee2;
    color: #364850;
    justify-content: center;
    display: flex;
    width: 45%;
    height: 50px;
    align-items: center;
    font-size: 16px;
    margin-bottom: 5px;
    margin-left: 5%;
    margin-top: 10px;
}

.rootVodafone {
    margin-top: 10%;
}

.imgButton {
    margin-left: 5px;
    height: 15px;
    width: 9px;
}

.addedTextNewsanMagento {
    display: flex;
    color: #364850;
}

.addedText {
    display: flex;
    color: #364850;
}

.slider {
    margin-top: 15px;
}

.sliderPrisma {
    margin-top: 25px;
}

.sliderContent {
    width: 40%;
    height: 300px;
    padding: 0 15px 0 15px;
}

.sliderContentPrisma {
    width: 50%;
}

.imgSliderPrisma {
    margin: auto;
}

.sliderContent h3 {
    color: #364850;
    font-size: 20px;
    text-align: left;
    margin-left: 5px;
    height: 10%;
}

.sliderContentPrisma h3 {
    color: #364850;
    font-size: 24px;
    text-align: left;
    margin-left: 10%;
    height: 10%;
}

.li {
    list-style: none;
    color: #364850;
    font-size: 14px;
    width: 80%;
    margin-left: 5px;
}

.liPrisma {
    list-style: none;
    color: #364850;
    font-size: 18px;
    width: 90%;
    margin-left: 10%;
}

.imgMobileAndDesktop {
    width: 100%;
    margin-top: -65px;
}

.imgMobileAndDesktopFelfort {
    width: 100%;
    margin-top: -65px;
}

.imgMobileAndDesktopExo {
    width: 100%;
    min-height: 200px;
    position: relative;
    bottom: 50px;
}

.containerImgMobileAndDesktop {
    width: 100%;
    position: absolute;
    background-color: #f1f3f6;
    height: 886px;
    height: 400px;
    margin: 70px 0 20px 0;
}

.containerImgMobileAndDesktopFelfortMobile {
    width: 100%;
    position: absolute;
    background-color: #f1f3f6;
    height: 400px;
    margin: 70px 0 20px 0;
}

.containerImgMobileAndDesktopExoMobile {
    width: 100%;
    position: absolute;
    background-color: #f1f3f6;
    height: 150px;
    margin: 50px 0;
}

.otherProjects,
.otherProjectsExo {
    margin-top: 20px;
}

.buttonsRootNewsanMagento {
    background-color: #f1f3f6;
    min-height: 500px;
}

.buttonsRoot,
.buttonsRootGpsFarma {
    min-height: 600px;
    margin-bottom: 20px;
}

.buttonsRootExo {
    min-height: 400px;
    margin-bottom: 20px;
}

.containerImgBannerDesktop {
    display: none;
}

.containerImgMobileAndDesktopNewsanMagento {
    margin-top: 25%;
}

.vodafoneButton a {
    text-decoration: none;
    width: 90%;
    margin: auto;
}

.imgMobileAndDesktopGpsFarma {
    max-width: 100%;
}

@media (min-width: 800px) {
    .imgTechnology {
        display: none;
    }

    .technology h3 {
        font-size: 68px;
        margin: 30px 0 0 0;
    }

    .technology p {
        font-size: 40px;
        margin: 5px 0 10px 0;
    }

    .technology p:last-word {
        font-weight: bold;
    }

    .technology_text {
        display: flex;
        width: 50%;
        font-size: 18px;
        margin: 20px 0 0 0;
    }

    .technology_textStoreNewsan {
        display: flex;
        width: 35%;
        font-size: 18px;
        margin: 20px 0 0 0;
    }

    .technology_subtitle {
        color: #364850;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 55px !important;
        margin-bottom: 0;
    }

    .technology_subtitle h4 {
        padding-right: 0.5%;
        color: #364850;
        font-size: 32px;
        word-spacing: 0;
    }

    .technology_subtitle p {
        padding-right: 0.5%;
        color: #364850;
        font-size: 28px !important;
        word-spacing: 0;
        padding-top: 5px;
    }

    .technology_subtitlePrisma {
        color: #364850;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 55px !important;
        margin-bottom: 0;
    }

    .technology_subtitlePrisma h4 {
        padding-right: 0.5%;
        color: #364850;
        font-size: 32px;
        word-spacing: 0;
    }

    .technology_subtitlePrisma p {
        padding-right: 0.5%;
        color: #364850;
        font-size: 28px !important;
        word-spacing: 0;
        padding-top: 5px;
    }

    .technology_subtitleStoreNewsan {
        color: #364850;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 55px !important;
        margin-bottom: 0;
    }

    .technology_subtitleStoreNewsan h4 {
        padding-right: 0.5%;
        color: #364850;
        font-size: 32px;
        word-spacing: 0;
    }

    .technology_subtitleStoreNewsan p {
        padding-right: 0.5%;
        color: #364850;
        font-size: 28px !important;
        word-spacing: 0;
        padding-top: 5px;
    }

    .buttons {
        padding-left: 0;
        width: 91%;
        margin: auto;
        margin-top: 10px;
    }

    .buttonsOtherProjects {
        width: 50%;
        margin-left: 150px;
        margin-top: 25px;
    }

    .buttonsOtherProjectsStoreNewsan {
        width: 45%;
        margin-top: 25px;
        margin-left: 12%;
        margin-top: 2%;
        position: absolute;
        height: 150px;
    }

    .buttonsOtherProjectsStoreNewsan h3 {
        font-size: 38px;
        width: 60%;
        margin-left: 10%;
        text-align: left;
        color: #364850;
        font-weight: 300;
        font-style: italic;
    }

    .buttonsOtherProjects h3 {
        font-size: 38px;
        width: 60%;
        margin-left: 1px;
        text-align: left;
        color: #364850;
        font-weight: lighter;
        font-style: italic;
    }

    .buttonsOtherProjectsExo {
        width: 45%;
        margin-left: 17%;
        margin-top: 5%;
        position: absolute;
        z-index: 1;
    }

    .buttonsOtherProjectsExo h3 {
        font-size: 36px;
        width: 60%;
        text-align: left;
        color: #364850;
        font-weight: 300;
        font-style: italic;
        margin: 10px 0 10px 0;
    }

    .buttonsOtherProjectsNewsanMagento {
        width: 65%;
        margin-top: 25px;
    }

    .buttonsOtherProjectsNewsanMagento h3 {
        font-size: 42px;
        width: 47%;
        margin: auto;
        text-align: left;
        color: #364850;
        font-weight: 300;
        font-style: italic;
    }

    .buttonsContainer {
        width: 30%;
        position: absolute;
        z-index: 5;
    }

    .buttonsContainerNewsanMagento {
        margin: auto;
        width: 47%;
    }

    .buttonRoot {
        width: 30%;
        position: relative;
        margin-top: 30px;
    }

    .generalButtons {
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        margin-left: 60px;
        gap: 1.5rem;
    }

    .buttonsGpsFarma {
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-left: -13.5%;
        margin-top: 20px;
    }

    .buttonRootNewsanMagento {
        width: max-content;
        padding-right: 0.5%;
        margin: 0;
        min-height: 48px;
    }

    .button, .buttonNewsanMagentoDesktop{
        border-radius: 10px;
        background-color: #3afee2;
        color: #364850;
        justify-content: center;
        display: flex;
        width: 190px;
        max-width: 190px;
        height: 70px;
        align-items: center;
        font-size: 22px;
        margin-bottom: 5px;
        padding: 0 40px;
    }

    .buttonNewsanMagentoDesktop {
        width: 210px;
        max-width: 210px;
        padding: 0;
    }

    .bRoot {
        width: 80%;
    }

    .bRootStoreNewsan {
        width: 200px !important;
        margin-left: 35%;
        margin-top: -7%;
    }

    .bRootStoreNewsan a {
        text-decoration: none;
        width: 100%;
    }

    .bRootNewsanMagento {
        width: 48%;
    }

    .container {
        width: 120%;
        height: 65px;
    }

    .containerNewsanMagento {
        width: 90%;
        height: 75px;
    }

    .addedTextNewsanMagento {
        display: flex;
        margin-right: 3px;
        font-size: 20px;
    }

    .addedText {
        display: flex;
        margin-right: 3px;
        font-size: 14px;
        min-width: 105px;
    }

    .store {
        display: none;
    }

    .storeDesktop,
    .storeDesktopExo,
    .storeDesktopFelfort {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 1220px;
    }

    .storeDesktopExo {
        padding-bottom: 14%;
        height: max-content;
    }

    .storeDesktopFelfort {
        padding-bottom: 6%;
        height: max-content;
    }

    .imageStore {
        width: 100%;
    }

    .imgDesktop {
        width: 100%;
        z-index: -1;
        position: relative;
    }

    .imgPhones {
        display: flex;
        width: 60%;
        z-index: 1;
        position: absolute;
        top: 250px;
        overflow: hidden;
        right: 0;
        max-height: 1050px;
    }

    .imgPhonesStoreNewsan {
        display: flex;
        width: 60%;
        z-index: 1;
        position: absolute;
        top: 380px;
        overflow: hidden;
        right: 0;
        max-height: 1050px;
    }

    .imgPhonesVodafone {
        display: flex;
        width: 45%;
        z-index: 1;
        position: absolute;
        top: 350px;
        overflow: hidden;
        right: 1%;
        max-height: 1700px;
    }

    .imgPhonesFelfort,
    .imgPhonesExo {
        display: flex;
        width: 45%;
        z-index: 1;
        position: absolute;
        top: 250px;
        overflow: hidden;
        right: 4%;
        max-height: 1050px;
        max-width: 700px !important;
    }

    .imgPhonesExo {
        right: 7%;
    }

    .storeTitle {
        width: 40%;
        position: relative;
        top: 100px;
        left: 50px;
    }

    .storeTitle h3 {
        width: 85%;
        margin: auto;
        font-size: 32px;
        color: #364850;
        font-weight: lighter;
        font-style: italic;
        font-weight: 300;
    }

    .storeTitle p {
        width: 85%;
        margin: auto;
        font-size: 18px;
        color: #364850;
        margin-top: 10px;
        font-weight: 400;
    }

    .storeTitleFelfort {
        width: 40%;
        margin-top: 70px;
        margin-left: 5%;
    }

    .storeTitleFelfort h3 {
        width: 90%;
        margin: auto;
        font-size: 32px;
        color: #364850;
        font-weight: 300;
        font-style: italic;
    }

    .storeTitleFelfort p {
        width: 90%;
        margin: auto;
        font-size: 18px;
        color: #364850;
        margin-top: 10px;
        font-weight: 400;
    }

    .methodology p {
        width: 74%;
        margin: auto;
        font-size: 36px;
        letter-spacing: 0.5;
        font-weight: 800;
        margin: 50px auto;
        line-height: 1;
    }

    .methodologyFelfort p {
        width: 50%;
        margin-left: 7%;
        font-size: 33px;
        color: #364850;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 1;
        margin-top: 5%;
    }

    .methodologyVodafone p {
        width: 48%;
        margin-left: 7%;
        font-size: 36px;
        color: #364850;
        font-weight: bold;
        position: absolute;
        letter-spacing: 0px;
        line-height: 1;
        margin-top: 9%;
    }

    .slider {
        display: none;
    }

    .methodologyRoot {
        display: flex;
        flex-direction: row;
        width: 75%;
        margin: 70px auto;
    }

    .methodologyRootPrisma {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin: auto;
        margin-top: 80px;
    }

    .methodologyContent {
        width: 25%;
        height: 250px;
        margin: auto;
    }

    .methodologyContent:first-child {
        width: 15%;
    }

    .methodologyContent h3 {
        color: #364850;
        font-size: 25px;
    }

    .methodologyRootFelfort {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 40%;
        margin-left: 7%;
        margin-top: -50px;
    }

    .methodologyContentFelfort {
        width: 45%;
        padding: 2px 12px 0 0;
        margin: 0;
        margin-bottom: 15px;
    }

    .methodologyContentFelfort h3 {
        color: #364850;
    }

    .imgMethodology {
        min-width: 100px;
        height: 110px;
    }

    .imgTechnologies {
        min-width: 120px;
        height: 130px;
        margin-top: -25px;
    }

    .methodologyRootPrisma .imgTechnologies {
        height: 110px;
        margin-top: 0;
    }

    .liMethodology {
        list-style: none;
        color: #364850;
        height: 60%;
        width: 100%;
        font-size: 16px;
    }

    .methodologyRootVodafone {
        width: 40%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: absolute;
        margin-left: 8%;
        bottom: 12%;
    }

    .methodologyContentVodafone {
        width: 49%;
        margin: 0;
        margin-bottom: 20px;
    }

    .imgMethodologyVodafone {
        min-width: 120px;
        min-height: 120px;
    }

    .methodologyContentVodafone h3 {
        margin-top: 10px;
        color: #364850;
        font-size: 22px;
        width: 100%;
        text-align: left;
        margin-left: 5px;
        height: 10%;
    }

    .liMethodologyVodafone {
        font-size: 16px;
        margin-top: -1px;
        list-style: none;
        color: #364850;
        width: 80%;
        margin-left: 5px;
    }

    .otherProjects {
        margin-top: 220px;
        height: auto;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 47%,
            rgba(241, 243, 246, 1) 48%
        );
        z-index: -1;
        position: relative;
        margin-bottom: -80px;
    }

    .otherProjectsNewsanMagento {
        margin-top: 200px;
    }

    .otherProjectsFelfort {
        margin-top: 100px;
    }

    .containerImgDesktopRight {
        display: flex;
        width: 35%;
        position: absolute;
        z-index: 1;
        margin-top: -53%;
        right: 0;
        margin-right: 130px;
    }

    .containerImgDesktopRightExo {
        display: flex;
        width: 35%;
        position: absolute;
        z-index: 1;
        margin-top: -56%;
        right: 0;
        margin-right: 12%;
    }

    .containerImgDesktopRightNewsanMagento {
        display: flex;
        width: 27%;
        position: absolute;
        z-index: 1;
        margin-top: -62%;
        right: 0;
        margin-right: 10%;
    }

    .containerImgDesktopRightStoreNewsan {
        display: flex;
        width: 25%;
        position: absolute;
        z-index: 1;
        margin-top: -51%;
        right: 0;
        margin-right: 12%;
    }

    .imgDesktopRight {
        width: 100%;
        max-height: 2000px;
    }

    .containerImgDesktopRightFelfort {
        display: flex;
        width: 50%;
        position: absolute;
        z-index: 2;
        right: 0;
        margin-right: 3%;
    }

    .imgDesktopRightFelfort {
        width: 100%;
        margin-top: -135%;
    }

    .containerImgMobileAndDesktop {
        width: 50%;
        margin: 8%;
    }

    .containerImgMobileAndDesktopNewsanMagento {
        width: 50%;
        margin-left: 11%;
        position: absolute;
        margin-top: 25%;
    }

    .containerImgMobileAndDesktopStoreNewsan {
        width: 50%;
        margin-left: 10%;
        position: absolute;
        margin-top: 18%;
    }

    .containerImgMobileAndDesktopExo {
        width: 20%;
        position: absolute;
        z-index: 3;
        right: 44%;
        margin-top: 16%;
    }

    .containerImgMobileAndDesktopFelfort {
        width: 25%;
        position: absolute;
        z-index: 3;
        right: 45%;
        margin-top: 12%;
    }

    .imgMobileAndDesktop {
        max-height: 1650px;
    }

    .imgMobileAndDesktopExo {
        min-width: 1080px;
        min-height: 500px;
        bottom: 200px;
    }

    .buttonsRootNewsanMagento {
        height: 1900px;
        margin-top: -10px;
    }

    .buttonsRoot {
        height: 600px;
        margin-bottom: 300px;
        background: #f1f3f6;
    }

    .buttonsRootExo {
        height: 600px;
        background: #f1f3f6;
    }

    .containerImgBannerDesktop {
        margin: 22px 30px 0;
        height: 78px;
        display: flex;
    }

    .containerImgBannerDesktop img {
        width: 100%;
        height: fit-content;
    }

    .imgBannerDesktop {
        display: flex;
    }

    .containerImgMobileAndDesktopGpsFarma {
        position: relative;
        width: 100%;
        z-index: 1;
        right: 0;
        margin-top: 0;
        bottom: 130px;
    }

    .imgMobileAndDesktopGpsFarma {
        width: 100%;
    }

    .displayNone {
        display: none;
    }

    .rootVodafone {
        margin-top: 37%;
        width: 40%;
        margin-left: 8%;
    }

    .otherProjectsTitleVodafone {
        width: 100%;
        font-size: 40px;
        text-align: left;
        color: #364850;
        font-weight: lighter;
        font-style: italic;
    }

    .containerVodafone {
        width: 32%;
        height: 60px;
        align-items: center;
        font-size: 16px;
        margin-bottom: 5px;
        margin-left: 0;
        margin-top: 10px;
    }

    .buttonsRootNewsanMagento {
        max-height: 1000px;
    }

    .buttonsOtherProjectsNewsanMagento {
        position: absolute;
        margin-top: 5%;
    }
}

@media (min-width: 1900px) {
    .methodologyFelfort p {
        width: 60%;
        font-size: 36px;
    }

    .storeDesktopExo {
        padding-bottom: 10%;
    }
}