.root {
    background-image: url('../../../assets/backgroundMobile.svg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 330px;
    
}

.img, .ecommerceImg {
    width: 100%;
    height: 330px;
    object-fit: contain;
    padding-left: 8%;
}

.ecommerceImg {
    height: 360px;
}

@media(min-width: 290px) {
    .root {
        background-position: 0 -25px;
    }
}

@media(min-width: 300px) {
    .root {
        background-position: 0 -20px;
        min-height: 450px;
    }
}

@media(min-width: 310px) {
    .root {
        background-position: 0 -25px;
        min-height: 460px;
    }
}

@media(min-width: 320px) {
    .root{
        background-position: 0 -30px;
        min-height: 470px;
    }
}

@media(min-width: 330px) {
    .root {
        background-position: 0 -45px;
    }
}

@media(min-width: 340px) {
    .root {
        background-position: 0 -45px;
        min-height: 490px;
    }
}

@media(min-width: 350px) {
    .root{
        background-position: 0 -75px;
    }
}

@media(min-width: 360px) {
    .root{
        background-position: 0 -85px;
        min-height: 470px;
    }
}

@media(min-width: 370px) {
    .root {
        background-position: 0 -85px;
        min-height: 480px;
    }
}

@media(min-width: 380px) {
    .root {
        background-position: 0 -75px;
        min-height: 510px;
    }
}

@media(min-width: 390px) {
    .root {
        background-position: 0 -110px;
    }
}

@media(min-width: 400px) {
    .root {
        background-position: 0 -130px;
    }
}

@media(min-width: 410px) {
    .root {
        background-position: 0 -140px;
    }
}

@media(min-width: 420px) {
    .root {
        background-position: 0 -160px;
    }
}

@media(min-width: 430px) {
    .root {
        background-position: 0 -170px;
    }
}

@media(min-width: 440px) {
    .root {
        background-position: 0 -190px;
    }
}

@media(min-width: 450px) {
    .root {
        background-position: 0 -200px;
    }
}

@media(min-width: 460px) {
    .root {
        background-position: 0 -210px;
    }
}

@media(min-width: 470px) {
    .root {
        background-position: 0 -230px;
    }
}

@media(min-width: 480px) {
    .root {
        background-position: 0 -250px;
    }
}

@media(min-width: 490px) {
    .root {
        background-position: 0 -260px;
        min-height: 500px;
    }
}

@media(min-width: 500px) {
    .root {
        background-position: 0 -280px;
        min-height: 510px;
    }
}

@media(min-width: 510px) {
    .root {
        background-position: 0 -290px;
        min-height: 520px;
    }
}

@media(min-width: 530px) {
    .root {
        background-position: 0 -280px;
        min-height: 530px;
    }
}

@media(min-width: 540px) {
    .root {
        background-position: 0 -290px;
        min-height: 540px;
    }
}

@media(min-width: 550px) {
    .root {
        background-position: 0 -310px;
    }
}

@media(min-width: 560px) {
    .root {
        background-position: 0 -325px;
    }

    .img, .ecommerceImg {
        width: 100%;
        height: 360px;
        object-fit: contain;
        padding-left: 8%;
    }
    
    .ecommerceImg {
        height: 410px;
    }
}

@media(min-width: 570px) {
    .root {
        background-position: 0 -335px;
    }
}

@media(min-width: 580px) {
    .root {
        background-position: 0 -350px;
    }
}

@media(min-width: 590px) {
    .root {
        background-position: 0 -365px;
    }
}

@media(min-width: 600px) {
    .root {
        background-position: 0 -380px;
    }
}

@media(min-width: 610px) {
    .root {
        background-position: 0 -400px;
    }
}

@media(min-width: 620px) {
    .root {
        background-position: 0 -420px;
    }
}

@media(min-width: 630px) {
    .root {
        background-position: 0 -430px;
    }
}

@media(min-width: 640px) {
    .root {
        background-position: 0 -450px;
    }
}

@media(min-width: 650px) {
    .root {
        background-position: 0 -460px;
    }
}

@media(min-width: 660px) {
    .root {
        background-position: 0 -475px;
    }
}

@media(min-width: 670px) {
    .root {
        background-position: 0 -485px;
    }
}

@media(min-width: 680px) {
    .root {
        background-position: 0 -500px;
    }
}

@media(min-width: 690px) {
    .root {
        background-position: 0 -520px;
    }
}

@media(min-width: 700px) {
    .root {
        background-position: 0 -530px;
    }
}

@media(min-width: 710px) {
    .root {
        background-position: 0 -550px;
    }
}

@media(min-width: 720px) {
    .root {
        background-position: 0 -570px;
    }
}

@media(min-width: 730px) {
    .root {
        background-position: 0 -585px;
    }
}

@media(min-width: 740px) {
    .root {
        background-position: 0 -600px;
    }

    .img, .ecommerceImg {
        padding-left: 9%;
    }
}

@media(min-width: 750px) {
    .root {
        background-position: 0 -620px;
    }
}

@media(min-width: 760px) {
    .root {
        background-position: 0 -640px;
    }
}

@media(min-width: 770px) {
    .root {
        background-position: 0 -650px;
    }
}

@media(min-width: 780px) {
    .root {
        background-position: 0 -670px;
    }
}

@media(min-width: 790px) {
    .root {
        background-position: 0 -680px;
    }
}

@media(min-width: 800px) {
    .root {
        background-position: 0 -660px;
        min-height: 540px;
        padding-top: 70px;
    }

    .img, .ecommerceImg {
        padding-left: 18%;
        width: 80%;
        position: relative;
        top: -20px;
    }
}

@media(min-width: 840px) {
    .root {
        background-position: 0 -680px;
    }
}

@media(min-width: 850px) {
    .root {
        background-position: 0 -690px;
    }
}

@media(min-width: 860px) {
    .root {
        background-position: 0 -700px;
    }
}

@media(min-width: 870px) {
    .root {
        background-position: 0 -720px;
    }
}

@media(min-width: 880px) {
    .root {
        background-position: 0 -735px;
    }
}

@media(min-width: 890px) {
    .root {
        background-position: 0 -745px;
    }
}

@media(min-width: 900px) {
    .root {
        background-position: 0 -755px;
    }
}

@media(min-width: 910px) {
    .root {
        background-position: 0;
        background-image: url('../../../assets//backgroundHome.svg');
    }
}

@media(min-width: 1100px) {
    .root {
        background-position: 0 -20px;
    }
}

@media(min-width: 1200px) {
    .root {
        background-position: 0 -70px;
    }

    .img, .ecommerceImg {
        top: -150px;
        min-height: 700px;
    }
}

@media(min-width: 1300px) {
    .root {
        background-position: 0 -70px;
        max-height: 75vh;
        min-height: 75vh;
    }
}

@media(min-width: 1800px) {
    .root {
        background-position: 0 -100px;    
    }
}

@media(min-width: 1800px) {
    .root {
        background-position: 0 -130px;    
    }

    .img, .ecommerceImg {
        top: -350px;
        min-height: 840px;
        left: 4rem;
    }


}