.contactFormAndUbication {
    display: flex;
    flex-direction: column;
}

@media(min-width: 1028px) {
    .contactFormRoot { 
        width: 50%;
    }
 
    .contactFormAndUbication {
        flex-direction: row-reverse;
        margin-top: -4px;
    }
}