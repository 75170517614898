.root {
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0px;
    color: #364850;
}

.title {
    border-bottom: .1rem solid #364850;
    font-size: 22px;
    min-width: 90%;
    margin: 10px auto;
    font-weight: 800;
} 

.name {
    font-size: 13px;
    text-transform: uppercase;
    margin: 0 4px;
}

.slideItemOne, .slideItemTwo {
    margin: auto;
    max-width: 95%;
    display: flex!important;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 15px;
}

.slideRoot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.root > div > ul > li > button::before {
    font-size: 15px;
    margin-top: 10px;
}

@media(min-width:800px) {
    .root {
        display: none;
    }
}