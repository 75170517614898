.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: url('../../../assets/backgroundDropdown.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    left: 0;
    font-family:'Montserrat', sans-serif;
    padding-bottom: 6rem;
    z-index: 60;
    opacity: 1;
    animation-name: popupAnimation;
    animation-duration: 1s;
    top: 0;
    min-height: 85vh;
    max-height: 85vh;
}

@keyframes popupAnimation {
    0%{top: -50rem;}
    100%{top: 0rem;}
}

.newContainer  {
    width: 98%;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: url('../../../assets/backgroundDropdown.png');
    background-size: 100% 100%;
    background-position: center top;
    background-repeat: no-repeat;
    position: fixed;
    top: -70rem;
    font-family:'Montserrat', sans-serif;
    letter-spacing: .4rem;
    padding-bottom: 6rem;
    z-index: 10;
    opacity: 1;
    animation-name: newContainer;
    animation-duration: 1s;
}

@keyframes newContainer {
    0%{top: 0rem;}
    100%{top: -70rem;}
}

.items, .itemsEcommerce {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.itemsEcommerce {
    position: relative;
    top: 50px;
}

.item {
    color: #3AFEE2;
    text-transform: uppercase;
    font-size: 25px;
    letter-spacing: 1px;
    outline: none;
    margin-top: 0;
    font-weight: 500;
}

.link {
    text-decoration: none;
}

.contact {
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-style: italic;
    font-weight: 500;
}

.header {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.closeImg {
    display: flex;
    max-width: 25px;
    max-height: 25px;
    min-width: 25px;
    min-height: 25px;
    position: absolute;
    align-self: flex-end;
    padding-right: 40px;
    top: 20px;
}

.languageContainer , .languageContainerEcommerce {
    align-self: center;
    margin-top: 20px;
    display: block;
}

.languageContainerEcommerce {
    position: relative;
    top: 102px;
}

.contactImage {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: -10px;
    position: relative;
    top: 10px;
}

.logo {
    margin: auto;
    position: relative;
    left: -10px;
    min-width: 30%;
    min-height: 25px;
    margin-top: 10px;
}

@media(min-width:800px) {
    .item {
        cursor: pointer;
        font-size: 24px;
        letter-spacing: 1px;
        margin-top: -5px;
    }

    .item:hover {
        color: #fff;
        cursor: pointer;
        padding-left: 90px;
        transition: .5s;
    }

    .contact:hover {
        color:#3AFEE2;
        cursor: pointer;
    }

    .contact {
        position: relative;
        left: 3rem;
        font-size: 25px;
        letter-spacing: .5px;
        margin-top: 50px;
    }

    .contactImage {
        margin-left: 5rem;
        margin-top: 34px;
        max-width: 30px;
        max-height: 30px;
        top: 17px;
    }

    .logo {
        cursor: pointer;
        left: -25px;
        margin-top: 20px;
    }

    .root {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }

    .languageContainer {
        display: none;
    }

    .header {
        padding: 20px;
    }

    .closeImg {
        max-width: 20px;
        max-height: 20px;
        cursor: pointer;
        padding-right: 70px;
        top: 30px;
    }

    .items, .itemsEcommerce {
        margin-top: 90px;
    }

    .container {
        min-height: 89vh;
        max-height: 89vh;
    }
}