.root {
    padding: 40px;
    min-height: 400px;
    width: 100%;
}

.contentSlider {
    max-width: 90%;
    min-width: 90%;
    margin: 0 auto; 
}

.title {
    font-size: 40px;
    color: #364850;
    max-width: 800px;
    min-width: 800px;
    font-weight: bold;
    margin-left: 15px;
    line-height: 45px;
}

.item {
    position: relative;
    display: flex!important;
    flex-direction: row!important;
    max-width: 85%;
    min-width: 85%;
    max-height: 300px;
    min-height: 300px;
    cursor: pointer;
    margin-bottom: 40px;
}

.openCloseIconAndtext {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 25%;
    max-width: 25%;
    min-height: 100%;
    padding-top: 15px;
}

.textRoot {
    padding: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: 10px;
}

.text {
    color: #364850;
    text-align: left;
    letter-spacing: .3px;
    max-width: 90%;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    min-height: 200px;
    max-height: 200px;
}

.owner {
    color: #364850;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    position: relative;
    top: -10px;
}

.client {
    font-weight: bolder;
}

.closeIcon {
    position: absolute;
    right: 10px;
    bottom: 8px;
}

.since {
    text-align: center;
    text-transform: uppercase;
    color: #707070;
    letter-spacing: .5px;
}

@media(min-width: 1500px) {
    .text {
        font-size: 18px;
        line-height: 27px;
    }
}

@media(max-width: 1100px) {
    .root {
        display: none;
    }
}