.root {
    margin-top: 400px;
}

.contactFormRoot {
    width: 100%;
}

@media (min-width: 800px) {
    .root {
        margin-top: 80%;
    }

    .contactFormRoot {
        width: 50%;
    }

    .contactFormAndUbication {
        flex-direction: row-reverse;
        max-height: 695px !important;
        min-height: 695px !important;
        position: relative;
        top: -5px;
        display: flex;
    }
}

@media (min-width: 1900px) {
    .root {
        margin-top: 73%;
    }
}
