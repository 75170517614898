.root {
    min-width: 100%;
    max-width: 100%;
    position: relative;
}

.image {
    object-fit: cover;
    min-width: 100%;
    min-height: 286px;
}

.container {
    width: 100%;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
}

.title {
    color: #FFFFFF;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    max-width: 300px;
    margin: auto;
}

.titleDesktop {
    display: none;
}

.button {
    color: #FFFFFF;
    border-radius: 16px;
    border: 3px solid #FFFFFF;
    width: 70%;
    margin: auto;
    font-weight: bold;
    text-transform: lowercase;
    padding: 15px 35px;
    margin-top: 35px;
    font-size: 16px;
}

.button::first-letter {
    text-transform: uppercase;
}

.button:focus {
    outline: none;
}

.button:hover {
    background-color: #F1F3F6B3; 
}

@media (min-width : 800px){
    .root {
        height: max-content;
    }

    .image {
       width: 100vh;
       height: 536px;
    }

    .title {
        display: none;
    }
 
    .container {
        top: 150px;
    }

    .titleDesktop {
        display: block;
        margin: 0;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 50px;
        letter-spacing: 1px;
        line-height: 50px;
        text-transform: uppercase;
    }
 
    .button {
        max-width: 250px;
        background-color: #2349E2;
        margin-top: 50px;
    }
 } 