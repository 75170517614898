.root {
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #364850;
    position: relative;
}

.title {
    text-transform: uppercase;
    margin-left: 2.6rem;
    letter-spacing: .8px;
    font-size: 26px;
}

.item {
    display: flex;
    margin-top: 4px;
}

.icon {
    color: #FFFF;
    font-size: 20px;
    font-weight: bold;
}

.li {
    list-style: none;
    font-size: 22px;
    position: relative;
    color: #364850;
}

.slide {
    max-width: 90%;
    min-height: 410px;
    max-height: 410px;
    position: relative;
    left: 1.2rem;
    background-color: #3AFEE2;
}

.slideRoot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.img {
    position: absolute;
    bottom: 0px;
    right: 20px;
    height: fit-content;
    width: 25%;
    max-width: 140px;
}

.button {
    background-color: rgba(54, 72, 80, .9);
    color: #FFFF;
    font-size: 15px;
    text-transform: capitalize;
    letter-spacing: .3px;
    width: 140px;
    height: 55px;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    left: 30px;
    bottom: 45px;
}

@media(min-width: 800px) {
    .root {
        display: none;
    }
}