.root {
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    height: 25px;
    width: 60px;
    padding: 5px 18px;
    font-family: 'Montserrat';
}

button {
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    padding: 2px;
    font-weight: bold;
    cursor: pointer;
}

.esButton {
   color: #2349E2;
   font-size: 19px;
   margin-right: 5px;
}

.enButton {
    color: #364850;
    font-size: 19px;
    margin-left: 5px;
}

@media(min-width: 800px) {
    .root {
        height: 35px;
        width: 90px;
        background-color: #ECEEF4;
    }
}