.root {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%; 
    max-height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.container {
    margin-left: 50px;
}