.root {
    background-color: #F1F3F6;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    color: #2349E2;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: .5px;
    justify-content: center;
    flex-direction: row;
}

.hubs {
    color: #2349E2;
    margin-left: 10px;
}

.titleRoot {
    display: flex;
    align-items: center; 
    max-height: 40px;
}

.textClosed {
    opacity: 1;
    animation: textClosed .3s forwards; 
    color: #364850;
    max-width: 90%;
}

@keyframes textClosed {
    from {
      opacity: 1; 
    }
    to {
      opacity: 0; 
    }
  }

.text {
    color: #364850; 
    opacity: 0;
    animation: text .3s forwards; 
    max-width: 90%;
    font-size: 14px;
    margin: 10px auto;
}

@keyframes text {
    from {
      opacity: 0; 
    }
    to {
      opacity: 1; 
    }
  }

.item,.itemOpen {
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-top: 8px;
    padding: 10px;
    box-shadow:  1px 1px 5px 2px #00000029;
    position: relative;
}

.item {
    height: 70px;
    transition: 0.7s ease;
}

.itemOpen {
    transition: 0.7s ease;
    height: 180px;
}

.itemTitle, .itemTitleOpen {
    color: #364850;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0;
    font-size: 16px;

}

.number, .openNumber {
    color: #2349E2;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: .5px;
    margin: 0 15px 0 15px;
}

.openNumber {
    color: #364850;
}

.imgOpen { 
    position: absolute;
    right: 10px;
    top: 60px;
    height: 20px;
    transition: .3s ease;
}

.img {
    position: absolute;
    right: 10px;
    top: 170px;
    transition: transform .3s;
    transform: rotate(-180deg);
    filter: grayscale(100%);
    height: 20px;
    transition: .3s ease;
}

.rootDesktop {
    display: none;
}

@media (min-width: 500px) {
    .items {
        max-width: 400px;
        margin: auto;
    }
}

@media (min-width: 1050px) {
    .items {
        display: flex;
        max-width: 80%;
        margin-top: 10px;
        margin: 0 0 10px 2.5rem;
    }

    .itemTitle {
        color: #FFFFFF;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 100%;
        font-size: 17px;
        margin-left: 1rem;
    }

    .itemTitleOpen {
       display: none;
    }

    .title {
        justify-content: flex-start;
        color: #364850;
        font-size: 40px;
        line-height: 1;
        margin-left: 3rem;
    }
    
    .titleRoot {
        flex-direction: column;
        align-items: flex-start;
    }

    .item {
        height: 140px;
        background-color: #2349E2;
        margin: 0 5px 0 5px;
        cursor: pointer;
    }

    .itemOpen {
        min-width: 500px !important;
        height: 140px;
        background-color: #3AFEE2;
        cursor: pointer;
        margin: 0 5px 0 5px;
    }

    .number {
        color: #3AFEE2;
        margin-top: 15px;
        font-size: 27px;
    }

    .openNumber {
        color: #364850;
        font-size: 50px;
        position: relative;
        margin: 0 0 30px 15px;
    }

    .imgOpen {
        filter: invert(100%) sepia(100%) saturate(1618%) hue-rotate(133deg) brightness(101%) contrast(99%);
        top: 20px;
        transform: rotate(270deg);
    }

    .img {
        transform: rotate(-270deg);
        top: 20px;
        width: 42px;
    }

    .text { 
        margin: -30px 20px 0 110px;
        font-size: 18px;
        max-width: 70%;
        min-width: 70%;
        position: relative;
    }
    
    .textClosed {
        display: none;
    }
        
    .items::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .items::-webkit-scrollbar-button:increment, .items::-webkit-scrollbar-button {
        display: none;
    }

    .items::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    .items::-webkit-scrollbar-thumb {
        background-color: rgba(54, 72, 80, .4);
        opacity: .4; 
        border-radius: 20px;
        border: 2px solid #F1F2F3;
    }

    .items::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    .imgOpen { 
        height: 42px;
    }

    .img {
        height: 42px;
    }

    .root {
        display: none;
    }

    .rootDesktop {
        display: block;
    }
}

@media (min-width: 1800px) {
    .items {
        max-width: 96%;
        margin-left: 20%;
    }

    .itemTitle {
        font-size: 20px;
    }

    .itemOpen {
        min-width: 700px !important;
       
    }

    .text { 
        font-size: 21px;
        max-width: 77%;
        min-width: 77%;
    }

}