.root {
    display: none;
}

.title {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-f3f3f7);
    text-align: left;
    letter-spacing: 0px;
    color: #F3F3F7;
    opacity: 1;
    text-transform: uppercase;
    font-size: 65px;
    margin-bottom: 0;
    position: relative;
    top: 30px;
    left: -20px;
}

.email {
    position: relative;
    top: 0;
    color: var(--unnamed-color-364850);
    letter-spacing: 1.1px;
    color: #364850;
    opacity: 1;
}

.email a {
    text-decoration: none;
    color: #364850;
}

.socialNetworksRoot {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: rotate(90deg);
    align-items: center;
    position: relative;
    left: 70px;
    gap: 1rem;
}

.info {
    align-items: center;
    display: flex;
    gap: 0 1rem;
}

@media(min-width: 1900px){
    .title {
        font-size: 90px;
    }

    .email {
        font-size: 24px;
    }
}

@media(min-width: 800px) {
    .root {
        display: block;
        transform: matrix(0, -1, 1, 0, 0, 0);
        position: absolute;
        right: -20px;
        top: 250px;
        z-index: 1;
    }
}