.title {
    text-align : center;
    margin : 50px 0 30px 0;
    font-size : 28px;
    color : #364850;
}

.container {
    margin-top : 15px;
}

.box {
    display : flex;
    flex-direction : column;
    align-items : center;
    margin : 5px ;
    margin-top : 35px;
    position : relative;
    max-height: 400px;
    overflow: hidden;
}

.img {
    max-width : 320px;
}

.linkMobile { 
    background-color : #3AFEE2;
    color : #364850;
    height : 55px;
    width: 90%;
    position :absolute;
    bottom: 6%;
    z-index: 1;
    justify-content :center;
    display : flex;
    flex-direction : row;
    color : #364850;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;
    border-radius: 10px;
}

.textButton {
    font-size: 20px;
}

.iconButtonSeeMore {
    margin-left: 10px;
}

.iconButton, .iconButtonRotate {
    margin-left : 10px;
}

.buttonContainer {
    margin-top : 10px;
    display : flex;
    flex-direction : row;
}

.button {
    display: flex;
    align-items: center;
    text-align: center;
    width: 85%;
    height: 55px;
    margin: auto;
    background-color:#2349E2;
    border-radius: 10px;
    margin-top: 15%;
    margin-bottom: 15%;
}

.buttonRoot {
    min-width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    justify-content: center;
}

.buttonRoot p {
    margin-left: 4%;
}

.rootDesktop {
    width: 85%;
    margin: 30px auto 60px auto;
    position: relative;
}

.rootDesktop h3 {
    text-align: left;
    font-size: 40px;
    width: 800px;
    color: #364850;
    margin-top: 25px;
}

.imgTitle {
    position: absolute;
    right: 0;
    top: 0;
}

.textButtonDesktop {
    font-size: 60px;
    font-weight: 800px;
    color: rgba(35, 73, 226, .4);
    cursor: pointer;
    padding: 5px;
}

.textButtonDesktop:hover {
    color:#2349E2;
}

.buttonsContainer {
    width: fit-content;
    margin: auto;
}

.OurProjectsDesktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
    width: 95%;
    margin: auto;
    position: relative;
}

.buttonsAndItems {
    position: relative;
    top: 50px;
    min-width: 100%;
}

.containerDesktop, .containerDesktopNewsan {
    display: flex;
    width: 45%;
    height: 515px;
    margin: 5px;
    max-height: 500px;
    position: relative;
    overflow: hidden;
}

.containerDesktop p, 
.containerDesktopNewsan p {
    width: 80%;
    margin: auto;
    font-size: 12px;
    font-weight: 500;
    color: #364850;
    margin-top: 20%;
    opacity: 0;
    font-weight: 500;
    transition: 0.5s;
}

.containerDesktop:hover p,
.containerDesktopNewsan:hover p {
    opacity: 1;
}

.imgProjectsDesktopHover {
    width: 90%;
    height: 100% !important;
    bottom: 0;
    right: 0;
    top: 0;
    position: absolute;
}

.containerDesktop:hover img
,.containerDesktopNewsan:hover img {
    bottom: 25%;
    margin-top: 95px;
    transition: 0.5s;

}

.containerDesktopNewsan:hover img {
    margin-top: 120px;
}

.containerDesktopNewsan img {
    margin-top: 40px;
}

.link {
    position: absolute;
    padding: 5px 20px;
    bottom: 5%;
    left: 40%;
    border-radius: 10px !important;
    background-color: #3AFEE2;
    color: #364850;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 23%;
    flex-direction: row;
    min-width: 130px;
    max-height: 12%;
    min-height: 12%;
    text-decoration: none !important;
    font-weight: 500;
    text-transform: none;
    font-size: 16px;
    color: #364850 !important;
}

.containerDesktop:hover .link,
.containerDesktopNewsan:hover .link {
    opacity: 1;
    transition: 0.5s;
}

.iconButtonDesktop {
    margin-top: 4px !important;
    margin-left: 3px;
    top: 22px;
}

.iconButtonRotate { 
    transform: rotate(-180deg);
}

@media(min-width: 870px) {
    .rootDesktop h3 {
        position: relative;
        left: -2rem;
    }

    .rootDesktop h3 {
        position: relative;
        top: 35px;
    }
}

@media(min-width: 1250px) {
    .rootDesktop h3 {
        left: -3rem;
    }
}

@media(min-width: 1450px) {
    .rootDesktop h3 {
        left: -4rem;
    }

    .imgProjectsDesktopHover {
        width: 80%;
        left: 50px;     
    } 
}

@media(min-width: 1650px) {
    .rootDesktop h3 {
        left: -5rem;
    }
}

@media(min-width: 1950px) {
    .rootDesktop h3 {
        left: -6rem;
    }
}

@media(min-width: 1800px) {
    .imgProjectsDesktopHover {
        width: 80%;
        left: 60px;     
    } 
    
    .containerDesktop, .containerDesktopNewsan  {
        min-height: 490px;
        max-height: 490px;
    }
}

@media (max-width: 800px) {
    .rootDesktop {
        display: none;
    }
}

@media (min-width: 800px) {
    .rootMobile {
        display: none;
    }
}
