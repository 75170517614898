.root {
    margin: 0;
    padding: 3.5rem;

}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.project {
    display: flex;
    flex-direction: column;
}

.project img {
    width: 100%;
    max-width: 300px; 
    margin: -65px;
}

li::marker {
  font-size: 20px;
  color: #2349E2;
}

ul li {
    line-height: 1.3rem;
}

@media (min-width: 768px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 980px){

    .title {
        flex-direction: column !important;
        align-items: flex-start !important;

    }

    .titleBlue {
        margin-left: 0px !important;
        margin-top: -2rem;
    }
}

.titleBlue {
    margin-left: 10px;
    letter-spacing: .5px;
    color: #2349E2;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 40px;
    color: #364850;
    font-weight: 600;
    margin-top: 5px;
    letter-spacing: .5px;
    z-index: 5;
}