.root {
    overflow: hidden;
    position: relative;
}

.footer {
    margin-top: 120px;
}

body {
    margin: 0;
}