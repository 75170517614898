.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #364850;
}

.itemsRoot {
    padding: 20px 0;
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: auto;
}

.img {
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
}

.imgItem {
    display: flex;
    align-items: center;
}

.imgItem:hover {
    filter: invert(20%) sepia(96%) saturate(3586%) hue-rotate(229deg) brightness(91%) contrast(94%);
    transition: .2s;
    cursor: default;
}

.imgItem:hover img {
    transition: .2s;
}

.name {
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 5px;
    text-align: center;
}

.title {
    border-bottom: .1rem solid #364850;
    font-size: 40px;
    width: 90%;
    margin: auto;
    font-weight: bolder;
    color: #364850;
}

@media(min-width: 1000px) {
    .title, .itemsRoot {
        width: 70%;
        margin-left: 24%;
    }
}

@media(min-width: 1200px) {
    .root {
        margin-top: 70px;
    }

    .title, .itemsRoot {
        margin-left: 22%;
    }

    .name {
        font-size: 18px;
    }

    .img {
        max-width: 50px;
        min-width: 50px;
        max-height: 50px;
        min-height: 50px;
    }
}

@media(min-width: 1300px) {
    .title, .itemsRoot {
        margin-left: 20%;
    }
} 

@media(min-width: 1420px) {
    .title, .itemsRoot {
        margin-left: 19%;
    }
} 

@media(min-width: 1550px) {
    .title, .itemsRoot {
        margin-left: 17%;
    }

    .name {
        font-size: 20px;
    }
}

@media(min-width: 1770px) {
    .title, .itemsRoot {
        margin-left: 16%;
        width: 65%;
    }

    .root {
        margin-top: 0;
    }
}