.root {
    max-width: 100%;
    min-height: 360px;
    display: flex;
    justify-content: center;
    color: #364850;
    background-color: #3AFEE2;
    padding: 45px 0;
}

.title {
    text-transform: uppercase;
    margin-left: .2rem;
    letter-spacing: .8px;
    font-size: 15px;
    text-align: center;
}

.item {
    display: flex;
    margin-top: 4px;
}

.icon {
    color: #FFFF;
    font-size: 20px;
    font-weight: bold;
}

.li, .specialLi {
    list-style: none;
    font-size: 15px;
    position: relative;
    margin-left: 3px;
    margin-top: 3px;
    letter-spacing: .5px;
}

.specialLi {
    font-weight: bold;
    font-size: 14px;
}

.list {
    min-height: 230px;
}

.slide {
    max-width: 33%;
    min-width: 33%;
    min-height: 350px;
    position: relative;
}

.slideRoot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.textAndButton {
    display:flex;
    width: 100%;
    margin: auto;
}

.button {
    background-color: rgba(54, 72, 80, .9);
    color: #FFFF;
    text-transform: capitalize;
    letter-spacing: .3px;
    width: 120px;
    height: 50px;
    border-radius: 16px;
    cursor: pointer;
    margin-left: 40px;
    align-items: center;
} 

.container {
    display: flex;
}

.img, .imgConsultancy, .imgDesign {
    margin-top: 40px;
    height: fit-content;
    position: relative;
    width: 20%;
}

.contentContainer {
    width: 100%;
    max-height: 250px;
}

.buttonAndImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vectorImg {
    margin-left: 4px;
}

@media(max-width: 800px) {
    .root {
        display: none;
    }
}

@media(min-width: 1000px) {
    .title {
        font-size: 22px;    
    }

    .li {
        font-size: 16px;
    }

    .specialLi {
        font-size: 19px;
    }

    .list {
        min-height: 250px;
    }
    .contentContainer {
        max-height: 260px;
    }
}

@media(min-width: 1400px) {
    .title {
        font-size: 29px;    
    }

    .list {
        min-height: 260px;
    }

    .slide {
        max-width: 25%;
        min-width: 25%;
    }

    .contentContainer {
        max-height: 320px;
    }

    .li {
        font-size: 20px;
    }
}

@media(min-width: 1700px) {
    .list {
        min-height: 180px;
    }

    .slide {
        max-width: 27%;
        min-width: 27%;
    }

    .title {
        margin-left: -3rem;
    }

    .contentContainer {
        max-height: 270px;
    }

    .li {
        font-size: 20px;
    }
}