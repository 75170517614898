.contactFormRoot { 
    width: 100%;
}
@media(min-width:800px) {
    .contactFormRoot { 
        width: 50%;
    }

    .contactFormAndUbication {
        flex-direction: row-reverse;
        display: flex;
        max-height: 695px!important;
        min-height: 695px!important;
        position: relative;
        top: -5px;
    }
}