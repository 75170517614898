.root {
    margin: auto;
    position: relative;
    max-width: 350px!important;
    max-height: 350px;
    min-height: 350px;
}

.text {
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    max-width: 90%;
    color: #20EDD0;
    margin: 80px auto;
    position: relative;
    top: 75px;
}

.openingQuoteMobile {
   position: absolute;
   left: 0;
   top: -50px;
}

.closingQuoteMobile {
    position: absolute;
    right: 0;
    bottom: -40px;
}

.openingQuote,
.closingQuote {
    display: none;
}
 
@media(min-width: 370px) {
    .text {
        font-size: 45px;
    }  
}

@media(min-width: 800px) {
    .openingQuote,
    .closingQuote {
        display: block;
        max-height: 40px;
        max-width: 40px;
        margin: 0 2px;
    }

    .text {
        max-width:max-content;
        min-width:max-content;
        color: #2349E2;
        font-size: 24px;
        top: 0px;
    }

    .openingQuoteMobile,
    .closingQuoteMobile {
        display: none;
    }

    .root {
        min-width: 80%;
        max-width: 80%;
        min-height: 70px;
        max-height: 70px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        bottom: 20px;
    }

    .rootContainer {
        position: relative;
        padding: 60px;
    }
}

@media(min-width: 900px) { 
    .text {
        font-size: 28px;
    }
}

@media(min-width: 1000px) { 
    .text {
        font-size: 30px;
    }

    .root {
       margin-top: 20px;
    }
}

@media(min-width: 1120px) { 
    .text {
        font-size: 35px;
    }
}

@media(min-width: 1420px) { 
    .text {
        font-size: 55px;
        text-align: center;
        padding: 0;
    }

    .root {
        max-width: 72%;
        min-width: 72%;
    }
}