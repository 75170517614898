.images {
    width: 100%;
    margin: auto;
    background-color: #F1F3F6;
    max-height: 380px;
    margin-top: 70px;
    margin-bottom: 115px;
    position: relative;
    z-index: -1;
}

.imgApe {
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    top: -45px;
}

.imgApePhono {
    width: 100%;
}

.containerApePhono {
    width: 70%;
    margin: auto;  
    position: relative;
    z-index: 1;
    top: -20px;
}

.titleAndText h3 {
    width: 90%;
    margin: auto;
    font-size: 35px;
    color: #364850;
    font-weight: 300;
    font-style: italic;
}

.titleAndText p {
    width: 90%;
    margin: auto;
    font-size: 16px;
    color: #364850;
    margin-top: 10px;
    font-weight: 400;
}

.methodology {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.methodologyContent {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.imgMethodology {
    margin: auto;
    max-height: 90px;
}

.methodologyTitle {
    color: #364850;
    font-size: 20px;
    width: 90%;
    text-align: left;
    margin-left: 5px;
    height: 10%;
    margin-top: 1px;
}

.li {
    margin-top: -1px;
    list-style: none;
    color: #364850;
    font-size: 14px;
    width: 80%;
    margin-left: 5px;
}

.contactFormRoot { 
    width: 100%;
}

.ape {
    margin-bottom: 150px;
}
@media(min-width:800px) {
    .ape {
        height: 1500px;
        position:relative;
        margin-bottom: 10px;
    }

    .methodology {
        width: 40%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: absolute;
        right: 0;
        top: 55%;
    }

    .methodologyContent{
        width: 49%;
        margin: 0;
    }

    .imgMethodology {
        min-width: 120px;
        min-height: 120px;
    }

    .methodologyTitle {
        margin-top: 10px;
        color: #364850;
        font-size: 24px;
        width: 100%;
        text-align: left;
        margin-left: 5px;
        height: 10%;
    }

    .li {
        font-size: 16px;
    }

    .images {
        position: absolute;
        max-height: 760px;
    }
    
    .imgApe {
        z-index: 2;
        position: absolute;
        right: 0;
        width: 65%;
        height: 850px;
        top:-25%;
    }
    
    .imgApePhono {
        width: 100%;
    }
    
    .containerApePhono {
        width: 50%;
        height: 950px;
        margin-top: 27%;
        margin-left: 5%;
        z-index: 2;
    }

    .titleAndText {
        margin-top: 120px;
        margin-left: 50px;
    }

    .titleAndText h3 {
        width: 52%;
        font-size: 32px;
        margin: 0;
    }
    
    .titleAndText p {
        margin: 10px 0;
        width: 30%;
        font-size: 18px;
    }

    .titleAndText {
        position: absolute;
        top: 6%;
        left: 4%;
    }

    .contactFormRoot { 
        width: 50%;
    }

    .contactFormAndUbication {
        flex-direction: row-reverse;
        display: flex;
        max-height: 695px!important;
        min-height: 695px!important;
        position: relative;
        top: -5px;
    }
}