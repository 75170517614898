.root {
    margin-top: 35%;
}

.contactFormRoot { 
    width: 100%;
}

@media(min-width:800px) {
    .root {
        margin-top: 20%;
    }

    .contactFormRoot { 
        width: 50%;
    }

    .contactFormAndUbication {
        flex-direction: row-reverse;
        position: relative;
        top: -4px;
        display: flex;
        max-height: 695px!important;
        min-height: 695px!important;
    }
}