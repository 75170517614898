.container {
   padding-left: 9%;
   height: 100vh;
   position: relative;
   border: 1px solid transparent;
}

.subTitle {
    font-size: 11px;
    color: #364850;
    font-weight: 600;
    margin: 100px 0 0 0;
}

.title span {
    color: #2349E2;
}

.title {
    color: #364850;
    font-weight: 600;
    margin: 0;
    max-width: 200px;
    margin-top: 5px;
    z-index: 5;
}

.footerMobile {
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('../../assets/backgroundFooter.svg');
    background-repeat: no-repeat;
    height: 170px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 51;
}

.footerImg {
    width: 110px;
    height: 40px;
    position: relative;
    top: 20px;
    margin: 0 10px;
}

.homeImgDesktop {
    display: none;
}

.desktopImage {
    width: 100%;
    position: relative;
    object-fit: contain;
    top: -40px;
}

.titleAndImage {
    max-height: 500px;
}

@media(max-width: 259px) { 
    .footerImg {
        width: 90px;
        height: 30px;
    }
}

@media(min-width: 320px) {
    .subTitle {
        font-size: 14px;
        letter-spacing: .5px;   
    }
    
    .title {
        font-size: 20px;
        letter-spacing: .5px;
        max-width: 250px;
        line-height: 20px;
    }

    .desktopImage {
        object-fit: cover;
        top: -40px;
    }
}

@media(min-width: 375px) {
    .title {
        font-size: 25px;
        max-width: 310px;
        line-height: 25px;
    }  
}

@media(min-width: 414px) {
    .title {
        font-size: 27px;
        max-width: 350px;
        line-height: 27px;
    }    
}

@media(min-width: 275px) {
    .container {
        padding-left: 10%;
    }
}

@media(min-width: 320px) {
    .container {
        padding-left: 12%;
    }
}

@media(min-width: 370px) {
    .container {
        padding-left: 13%;
    }
}

@media(min-width: 420px) {
    .container {
        padding-left: 15%;
    }

    .desktopImage {
        top: -70px;
        left: 20px;
    }
}

@media(min-width: 460px) {
    .container {
        padding-left: 17%;
    }
}

@media(min-width: 550px) {
    .container {
        padding-left: 18%;
    }

    .homeImgDesktop {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .desktopImage {
        height: 500px;
        top: -80px;
    }
}

@media(min-width: 650px) {
    .container {
        padding-left: 19%;
    }   
}

@media(min-width: 750px) {
    .container {
        padding-left: 20%;
    }
}

@media(min-width: 600px) {
    .title {
        font-size: 30px;
        max-width: 370px;
        line-height: 30px;
    }
}

@media(min-width: 610px) {
    .desktopImage {
        max-height: 450px;
        top: -60px;
        width: 500px;
    }
}

@media(min-width: 690px) {
    .title {
        font-size: 35px;
        max-width: 450px;
        line-height: 34px;
        max-height: 440px;
    }

    .desktopImage {
      top: -67px;
    }
}

@media(min-width: 800px) {
    .container {
        padding-left: 12%;
    }

    .subTitle {
        margin: 180px 0 0 0;
    }

    .title {
        font-size: 24px;
        line-height: 25px;
        max-width: 380px;
        min-width: 380px;
    }

    .titleAndImage {
        max-width: 80%;
        display: flex;
        align-items: flex-end;
    }

    .footerMobile {
        display: none;
    }

    .desktopImage {
      top: 150px;
      left: -80px;
    }
}

@media(min-width: 950px) {
    .title {
        font-size: 30px;
        max-width: 500px;
        min-width: 500px;
        line-height: 30px;
    }

    .subTitle {
        margin: 200px 0 0 0;
    }
}

@media(min-width: 1200px) {
    .title {
        font-size: 34px;
        max-width: 600px;
        min-width: 600px;
        line-height: 35px;
    }
}

@media(max-width: 1150px) {
    .socialNetworks {
        display: none;
    }
}

@media(min-width: 1300px) {
    .title {
        font-size: 36px;
        position: relative;
        top: -100px;
    }

    .subTitle {
        position: relative;
        top: -100px;
    }

    .desktopImage {
       max-height: 600px;
    }
}

@media(min-width: 1400px) { 
    .desktopImage {
        min-width: 550px;
        top: 90px;
    }
}

@media(min-width: 1600px) { 
    .title {
        font-size: 45px;
        line-height: 50px;
        max-width: 80px;
        min-width: 700px;
        top: -70px;
    }

    .subTitle {
        top: -70px;
    }

    .desktopImage {
        left: -30px;
        top: 250px;
        min-height: 700px;
        min-width: 700px;
    }
}